import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { ModalComponent } from './components/modal/modal.component';
import { PopupModalComponent } from './components/popup-modal/popup-modal.component';
import { LoaderModalComponent } from './components/loader-modal/loader-modal.component';
import { SharedModule } from '../shared/shared.module';
import { PopupWithCheckboxModalComponent } from './components/popup-with-checkbox-modal/popup-with-checkbox-modal.component';
import { FormsModule } from '../forms/forms.module';
import { PopupWithTextareaModalComponent } from './components/popup-with-textarea-modal/popup-with-textarea-modal.component';
import { StatusModalComponent } from './components/status-modal/status-modal.component';

@NgModule({
  declarations: [
    ModalComponent,
    PopupModalComponent,
    LoaderModalComponent,
    PopupWithCheckboxModalComponent,
    PopupWithTextareaModalComponent,
    StatusModalComponent,
  ],
  exports: [ModalComponent, PopupModalComponent],
  imports: [CommonModule, TranslateModule, SharedModule, FormsModule],
})
export class ModalModule {}
